<template>
  <div class="billing-modal">
    <sweet-modal ref="modal" overlay-theme="dark" @close="onClose"
                 :hideCloseButton="true">
      <div class="modal-container">
        <div class="modal-title">
          <div class="body2-medium main">카드 관리</div>
          <i class="material-icons sub3 unselect" @click="$refs.modal.close()">close</i>
        </div>

        <div class="modal-content">
          <!-- 카드 추가 폼 -->
          <card-input-form v-if="vForm" :isModal="true" :value.sync="value"
                           @enrollCard="cardReg"
                           @cancel="vForm=false"></card-input-form>

          <!-- 등록된 카드 목록 -->
          <div v-else class="flex" style="flex-direction: column;height:100%">
            <div style="flex:1">
              <div class="subtitle5 main modal-item">등록된 카드 목록</div>
              <div v-for="(item,idx) in list" :key="'card-item-'+idx"
                   class="modal-item flex-between" :class="{'unselect': radio}" @click="clickItem(item,idx)">
                <div class="flex-align">
                  <div v-if="radio" :class="{'radio-outer-selected': item.selected, 'radio-outer': !item.selected}" style="margin-right:8px">
                    <div class="radio-selected" v-if="item.selected"></div>
                  </div>
                  <div class="body3 sub">{{ `${item.card_name} ${item.num}` }}</div>
                </div>
                <i v-if="!radio" class="unselect material-icons sub4" @click="clickDelete(item, idx)">close</i>
              </div>
              <div class="unselect body3 primary" style="margin-top:12px" @click="vForm=true">+ 카드 추가하기</div>
            </div>
            <div v-if="radio" class="flex-between" style="margin-top: 20px">
              <button class="button is-gray"
                      style="flex:1;margin-right:8px" @click="$refs.modal.close()">취소</button>
              <button class="button is-primary"
                      style="flex:1; margin-left: 8px" @click="clickSelect">선택 완료</button>
            </div>
          </div>
        </div>
      </div>
    </sweet-modal>
    <div class="alert-modal">
      <sweet-modal ref="alert" overlay-theme="dark">
        <div class="alert-content">
          <svg width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 16H12.01" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 8V12" stroke="#ff6600" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <div class="subtitle4 main" style="margin:24px 0 8px 0">해당 카드는 서비스에서 사용 중입니다.</div>
          <div class="body4 sub4">해당 신용카드는 {{ usingService }} 서비스에서<br>사용중입니다.<br>카드를 삭제하시려면 해당 서비스의 결제 카드를<br>다른 카드로 변경한 뒤 시도해주세요.</div>
          <button class="button is-gray" style="margin-top:80px;width:158px;height:40px" @click="$refs.alert.close()">확인</button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue'
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import CardInputForm from "../module/CardInputForm";

  export default {
    name: "UserBillingModal",
    mixins: [
      UserAPIMixin
    ],
    props: {
      radio: {
        type: Boolean,
        default: false
      },
      serviceId: {
        type: Number,
        default: 0
      }
    },
    components: {
      CardInputForm,
      SweetModal
    },
    mounted() {
      this.$refs.modal.open();
      this.getData();
    },
    data() {
      return {
        list: [],
        vForm: false,
        value: {
          pg_type: 'payple',
          card_num: '',
          ex_year: '',
          ex_month: '',
          birth: ''
        },
        usingService: '',
        selectedItem: undefined
      }
    },
    methods: {
      clickItem(item, idx) {
        if(this.radio) {
          this.list.forEach((o,i)=>{
            o.selected = i === idx;
          });
          this.selectedItem = item;
        }
      },
      clickSelect() {
        this.$emit('select', this.selectedItem);
        this.$refs.modal.close();
      },
      getData() {
        this.request.user.get('launchpack/card_bill').then(res => {
          this.list = res.data.map(item=>{
            item.selected = false;
            return item;
          });
          if(this.list.length > 0) {
            this.clickItem(this.list[0], 0);
          }
        });
      },
      cardReg() {
        this.setLoading('카드 등록 중');
        this.request.user.post('launchpack/card_bill', this.value).then(res => {
          this.clearLoading();
          if(res.status === 200) {
            if(res.data.result === 'error') {
              let msg = '등록에 실패하였습니다. 관리자에 문의하여주십시오.';
              if(res.data.message) {
                msg = res.data.message.split(' - ').slice(-1)[0];
              }
              this.toast(msg);
            } else {
              this.getData();
              this.vForm = false;
              this.value = {
                pg_type: 'payple',
                card_num: '',
                ex_year: '',
                ex_month: '',
                birth: ''
              };
            }
          }
        }).catch(()=>{
          this.clearLoading();
          this.toast('등록에 실패하였습니다. 관리자에 문의하여 주십시오.');
        });
      },
      clickDelete(item, idx) {
        this.request.user.get(`launchpack/card_bill/${item.id}`).then(res => {
          if(res.data.services.length>0) {
            this.usingService = res.data.services.toString().replace(/,/gi, ', ');
            this.$refs.alert.open();
          } else {
            this.setLoading('카드 삭제 중');
            this.request.user.delete(`launchpack/card_bill/${item.id}`).then(res => {
              this.clearLoading();
              if(res.status === 200) {
                this.list.remove(idx);
              }
            }).catch(()=>{
              this.clearLoading();
            });
          }
        });
      },
      onClose() {
        setTimeout(()=> {
          this.$emit('close');
        }, 500);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .modal-title
    background-color $gray2
    display flex
    justify-content space-between
    padding 16px 24px
  .modal-content
    padding 12px 32px
    text-align left
    height 548px
    overflow-y auto

  .modal-item
    border-bottom 1px solid $gray1
    padding 12px 0

  .alert-content
    padding 90px 32px
    text-align center

  .radio-outer
    border 1px solid $gray1
    border-radius 100%
    width 20px
    height 20px

  .radio-outer-selected
    background-color $primary
    border-radius 100%
    width 20px
    height 20px
    display flex
    align-items center
    justify-content center

  .radio-selected
    border-radius 100%
    width 16px
    height 16px
    background-color transparent
    border 3px solid white



</style>

<style lang="stylus">
.billing-modal
  .sweet-modal.is-alert .sweet-content
    padding 0
@media (min-width:501px)
  .billing-modal
    .sweet-modal
      width 500px !important

@media (max-width:500px)
  .billing-modal
    .sweet-modal
      width 100% !important

</style>
